@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource/montserrat';

@layer base {
  body {
    @apply font-primary;
  }
}
